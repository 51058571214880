import Navbar from '../components/Navbar';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';

import Director from 'components/NextconLabDirector/Director.jsx';
import Header from 'components/NextconLabDirector/header.jsx';
import DirectorMobile from 'components/NextconLabDirector/Director_mobile';
import Body from 'components/NextconLabDirector/Body.jsx';


function LabDirector() {

    const isMobile = useSelector(state => state.windowSize.isMobile);
  
    return (
      <>
      {isMobile ? ( 
        <>
          <MobileNavbar />
          <Header />
          <hr />
          <DirectorMobile />
          <hr />
          <Body />
          <hr />
          <MobileFooter />
        </>
      ) : (
        <>
          <Navbar />
          <Header />
          <hr />
          <Director />
          <hr />
          <Body />
          <hr />
          <Footer />
        </>
      )}
      </>
    );
  }
  
  export default LabDirector;
import React from 'react';

import { Flex, Text, Link} from '@chakra-ui/react';

import { newsItems } from "../NextconLabNews/NewsList";

function BodyPart() {
    return (
        <>
            <div style={{ display: 'flex', minHeight: '20vh', padding: '20px' }}>
                {/* Left Section */}
                <div style={{ flex: 1., padding: '20px', fontSize: `${18}px`}}>
                    <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/UZjw23oOAAk"
                        title="YouTube video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <br />
                    <p><span style={{fontWeight: 'bold', textDecoration: 'underline', color:'darkgreen', fontSize: `${24}px`}}>Current Research Interests: </span></p>
                    
                    <ul style={{ listStyleType: 'none', padding: 15, textAlign: 'left' }}>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}>Semantic communications, Generative AI and Large Language Models for Next Generation Networks</span>
                        </li>

                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}> AI-driven Security for Smart Critical Infrastructures</span>
                        </li>

                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}> Secure, Resilient and Reliable Communications for 6G and Beyond</span>
                        </li>

                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}> AI-backed event detection, actuation and planning for Connected Vehicles</span>
                        </li>

                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}> AI-backed event detection, actuation and planning for Connected Vehicles</span>
                        </li>

                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}> Internet of Things / Internet of Vehicles</span>
                        </li>

                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{fontWeight: 'bold', color:'darkgreen', fontSize: `${18}px`}}> Crowdsensing and vehicular social networks</span>
                        </li>
                    </ul>
                    <p>
                        <span style={{ fontWeight: 'bold', fontSize: `${18}px`, color:'#6495ED'}}>  Looking for our surveys or tutorials in these areas?</span> <a href="/surveys-tutorials" rel="noopener noreferrer" style={{fontWeight: 'bold', color: 'darkblue', textDecoration: 'underline' }}>Click here</a>
                    </p>
                    <br />
                    <Flex direction="column" align="center" width= '70%' marginLeft='15%' marginRight='15%'>
                        <Text style={styles.title}>Top-15: Latest News</Text>
                    </Flex>
                    <table style={{marginBottom:'5px', border: '0px solid black', background:"gainsboro"}}>
                        <tbody>
                            {newsItems.slice(0, 15).map((item, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '4px', border: '0px solid black', fontSize: `${16}px` }}>
                                        <u><b> {item.date} </b></u>

                                        <ul style={styles.bulletList} align="justify"> <li>
                                            {item.text} {item.link ?  (<Link href={item.link} style={{ color: 'blue' }} target="_blank"><u>Click to view.</u></Link>) : null}
                                        </li> </ul> 
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <a href="https://www.tracemyip.org/" target="_blank" rel="noopener noreferrer">
                        <img
                        src="tip.gif"
                        alt="Tip"
                        style={{
                            marginTop: '10px', // Space between the table and the image
                            float: 'left', // Aligns the image to the bottom-left
                            width: '55px', // Adjust size as needed
                            height: '30px', // Adjust size as needed
                            cursor: 'pointer',
                        }}
                        />
                    </a>
                                
                </div>

                {/* Right Section */}
                <div style={{ flex: 1., padding: '20px', fontSize: `${18}px`}}>  
                    <p><span style={{ fontWeight: 'bold', textDecoration: 'underline'}}>Short bio:</span></p>
                    <p>-</p>
                    <p align="justify">
                        Dr. Burak Kantarci is a Full Professor and University Research Chair in AI-Enabled Secure 
                        Networking for Smart Critical Infrastructures in the School of Electrical Engineering and 
                        Computer Science at the University of Ottawa. Dr. Kantarci is the founding director of the 
                        Smart Connected Vehicles Innovation Centre (<span style={{ fontWeight: 'bold'}}>SCVIC</span>) and 
                        Next Generation Communications and Computing Networks Lab (<span style={{ fontWeight: 'bold'}}>NEXTCON</span>). 
                        Prior to joining the uOttawa, he was an assistant professor at Clarkson University in Potsdam, 
                        New York (2014-2016). In 2009-2014, he had also worked as a research fellow at the School of
                        Electrical Engineering and Computer Science of the University of Ottawa.
                    </p>
                    <br />
                    <p align="justify">
                    Dr. Kantarci is a co-author of 300+ peer-reviewed publications in established journals and 
                    conference proceedings sponsored by established publishers such as IEEE, ACM, Elsevier, 
                    and Springer. He has been listed among the <span style={{ fontWeight: 'bold'}}>top-2% cited scientists in telecommunications and networking</span> according 
                    to the "Science-wide author databases of standardized citation indicators" data reported by 
                    Stanford University since 2020. Since 2021, based on data collected from Microsoft Academic Graph, 
                    research.com has listed Dr. Kantarci among <span style={{ fontWeight: 'bold'}}>Canada's top computer scientists</span>. 
                    Dr. Kantarci holds an Exemplary Editor Award from IEEE Communications Surveys and Tutorials (2021),and is a joint 
                    holder of multiple best paper awards from various conferences, most recently from IEEE Globecom2021, 48th Wireless 
                    World Research Forum 2022, IEEE ICC2023, IEEE VCC2023 and IEEE Globecom 2024. He is a recipient of 
                    the <span style={{ fontWeight: 'bold'}}>Minister's Award of Excellence</span> from Ontario Ministry of Colleges and 
                    Universities (2021). Dr Kantarci is also the recipient of the <span style={{ fontWeight: 'bold'}}>IEEE Communications Software Technical Committee - 2023 Technical Achievement Award</span> for 
                    contributions to AI/ML-enabled communication network security and trustworthy sensing systems for the Internet of 
                    Things, and a winner of <span style={{ fontWeight: 'bold'}}>2024-25 George S. Glinski Award for Excellence in 
                    Research</span> in the Faculty of Engineering at uOttawa.
                    </p>
                    <br />
                    <p align="justify">
                    He has been the PI/co-PI of several federally/provincially-funded research projects supported by 
                    Natural Sciences and Engineering Research Council of Canada (NSERC), Canada Foundation for 
                    Innovation (CFI), Ontario Research Fund (ORF), U.S. National Science Foundation (NSF), Ontario 
                    Centres of Excellence (OCE), and Mathematics of Information Technology and Complex Systems (Mitacs). 
                    [Total funding over $9.5M]
                    </p>
                    <br />
                    <p align="justify">
                    Dr. Kantarci is a Distinguished Lecturer of the IEEE Communications Society (2023-2024) and IEEE Systems Council (2022-2024), Distinguished Speaker of the ACM (2019-2021), Senior Member of the ACM and a Senior Member of the IEEE, a member of the Sigma Xi, The Scientific Research Honor Society (∑Ξ), an editor for IEEE Communications Surveys and Tutorials, associate editor for IEEE Networking Letters, associate editor for IEEE Internet of Things Journal, associate editor for Vehicular Communications, associate editor for IEEE Access, area editor for IEEE Transactions on Green Communications and Networking. In 2019-2020, Dr. Kantarci served as the Chair 
                    of <a href="https://csim.committees.comsoc.org/" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline' }}>IEEE Communication Systems Integration and Modeling Technical Committee</a> of <a href="https://www.comsoc.org/" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline' }}>IEEE Communications Society</a>.  
                    He has been serving as a TPC member of GLOBECOM (since 2011) and ICC (since 13) in various tracks. He is also co-chairing the Workshop on Management of Cloud and Smart City Systems (MoCS) since 2012, and is a Symposium co-chair in IEEE GLOBECOM'18-Symp on Communication Systems QoS and Reliability Modeling, and a symposium chair in ICC 2022 Communication Systems QoS and Reliability Modeling Symposium. He served as the chair of two different tracks of IEEE Vehicular Technology Conference (VTC) in 2016 and 2019. He has also served as a symposium co-chair of IEEE International Conference on Communications and Networking in multiple symposia, most recently in 2021 as Cybersecurity track chair of IEEE Canadian Conference on Electrical and Computer Engineering, Security and Privacy Track Chair of IEEE International Smart Cities Conference (ISC2), and the general co-chair of IEEE BlackSeaCom 2021, and IEEE CAMAD 2021 conferences. In 2020, he served as the symposium chair for Machine Learning for Communications and Networking symposium. Previously, he had served as a TPC co-chair and a general co-chair of the IEEE International Workshop on Computer-Aided Modeling Analysis and Design of Communication Links and Networks (CAMAD) in 2014 and 2016, respectively. Since December 2012, Dr. Kantarci also served as an area editor of the IEEE ComSoc Ad Hoc Sensor Networks Technical Committee (AHSN-TC) Newsletter.
                    </p>
                    <br />
                    <p align="justify">
                    Dr. Kantarci is a member of  
                    the <a href="https://ocics.ca/" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline' }}>Ottawa-Carleton Institute for Computer Science</a> and <a href="https://ociece.ca/bi_ling/" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline' }}>Ottawa-Carleton Institute for Electrical and Computer Engineering</a>. 
                    Dr. Kantarci is a Professional Engineer (P.Eng) registered in the province of Ontario.
                    </p>
                </div>
            </div>


        </>
    );
}

const styles = {
    title: {
        fontSize: '32px',
        alignItems: 'center',
        fontWeight: 'bolder',
        marginBottom: '10px',
    },
    NewsLink: {
      color: 'darkblue ',
      textDecorationLine: 'underline',
      fontWeight: 'bold',
      margin: '0 2px',
      fontSize: '20px',
      },
      bulletList: {
          listStyleType: 'square', // Bullets for list items
          paddingLeft: '20px', // Indent items to line up text nicely
        },
  };



export default BodyPart;
import React from 'react';

function LabDirector() {
    return (
        <>
            <div style={{ display: 'flex', minHeight: '20vh', padding: '0 50px' }}>
                {/* Left Section */}
                <div style={{ flex: 0.9, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
                   <img
                        src="NEXTCON-BurakKantarci_Profile.jpg"
                        alt="Profile"
                        style={{ width: '60%', borderRadius: '10px' }}
                    />
                </div>

                {/* Center Section */}
                <div style={{ flex: 1.2, fontSize: `${20}px`, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
                    <h2 style={{ marginBottom: '10px' }}>University of Ottawa</h2>
                    <p style={{ marginBottom: '5px' }}>School of Electrical Engineering and Computer Science</p>
                    <p style={{ marginBottom: '5px' }}>Ottawa, ON, K1N 6N5</p>
                    <p style={{ marginBottom: '5px' }}>Phone: (613) 562-5800 ext 6955</p>
                    <p>Email: <a href="mailto:burak.kantarci@uOttawa.ca" style={{ textDecoration: 'underline', color: 'darkblue' }}>burak.kantarci@uOttawa.ca</a></p>
                </div>

                {/* Right Section */}
                <div style={{ flex: 0.9, padding: '20px', fontSize: `${18}px`}}>
                    <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'left' }}>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/publications.html" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Publication List</a>
                        </li>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/CV.pdf" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Curriculum Vitae (in pdf)</a>
                        </li>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/ResearchGrants.html" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Research Grants & Projects</a>
                        </li>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/awards.html" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Awards, Recognitions & Mentions</a>
                        </li>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://nextconlab.ca/" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>SCVIC and NEXTCON Lab</a>
                        </li>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/service.html" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Service and Memberships</a>
                        </li>
                        
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <span style={{ fontWeight: 'bold', color: 'green' }}>External</span>
                            <ul style={{ paddingLeft: '30px', listStyleType: 'circle' , marginLeft: '20px'}}>
                                <li>
                                <a href="https://scholar.google.com/citations?user=NBFqTFcAAAAJ&hl=en" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline'}}>Google Scholar</a> &nbsp;
                                <a href="https://www.linkedin.com/in/bkantarci/" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline' }}>LinkedIn</a> &nbsp;
                                <a href="https://www.researchgate.net/profile/Burak-Kantarci" rel="noopener noreferrer" target="_blank" style={{ color: 'darkblue', textDecoration: 'underline' }}>ResearchGate</a>
                                </li>
                            </ul>
                        </li>
                        
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/teaching.html" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Teaching</a>
                        </li>
                        <li style={{ marginBottom: '10px' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: 'black', transform: 'rotate(45deg)', marginRight: '10px' }}></span>
                            <a href="https://www.site.uottawa.ca/~bkantarc/personal.html" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 'bold', color: 'green', textDecoration: 'underline' }}>Personal Notes</a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Additional Paragraph at the Bottom */}
            <div style={{ padding: '20px 100px', textAlign: 'left', fontSize: `${20}px` }}>
                <p align="justify">
                In <span style={{ fontWeight: 'bold' }}>SCVIC and NEXTCON Lab</span>, my team and I are committed to creating an environment that is clearly 
                supportive, diverse, gender-balanced, collegial and open to the researchers with strongest technical 
                skills regardless of their background. I am looking for enthusiastic PhD students and 
                Postdoctoral Fellows who will work on the topics listed below (see: current research interests below). 
                Interested students or postdoc applicants may contact me via <a href="mailto:scvic-civic@uottawa.ca" style={{ fontWeight: 'bold', color: 'darkblue', textDecoration: 'underline' }}>scvic-civic@uottawa.ca</a> to inquire the possibility 
                of joining my team.
                </p>
            </div>


        </>
    );
}

export default LabDirector;
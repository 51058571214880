import React, { useState, useEffect } from "react";
import { Flex, Button, Image, SimpleGrid, Box, Menu, MenuButton, MenuList, MenuItem, Text} from "@chakra-ui/react";
import { useLocation, Link } from "react-router-dom"; // Import useLocation and Link
import { StyleSheet, css } from 'aphrodite';

function Navbar () {
    const location = useLocation(); // Get the current location
    const [activeLink, setActiveLink] = useState("/"); // Default to the home page

    

    useEffect(() => {
        // Update the activeLink based on the current location
        setActiveLink(location.pathname);
    }, [location.pathname]);
    return (
    <>
    <Box className={css(styles.parent)}>
        <Flex >
            <Image src="nextcon_logo.png" alt="nserc logo" width='5%' fit="contain" marginLeft={7} mt={2}/>
            <SimpleGrid columns={7} spacing={5} className={css(styles.nav)}> 
                <Link to='/' className={css(styles.link)}>
                    <Button 
                        variant='link'
                        className={css(styles.button, activeLink === "/" && styles.activeButton)}
                    >
                        Home
                    </Button>
                </Link>
                <Menu className={css(styles.link)}>
                    <MenuButton as={Button}  variant='link' className={css(styles.buttonMenu, (activeLink === "/members" || activeLink === "/lab-director" || activeLink === "/alumni") && styles.activeButton)}>
                        People
                    </MenuButton>
                    <MenuList>
                        <Link to='/lab-director' className={css(styles.link)}>
                            <MenuItem>Lab Director</MenuItem>
                        </Link>
                        <Link to='/members' className={css(styles.link)}>
                            <MenuItem>Members</MenuItem>
                        </Link>
                        <Link to='/alumni' className={css(styles.link)}>
                            <MenuItem>Previous Members</MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton as={Button} variant='link' className={css(styles.buttonMenu, (activeLink === "/publications" || activeLink === "/surveys-tutorials") && styles.activeButton)}>
                        Publications
                    </MenuButton>
                    <MenuList>
                        <Link to='https://www.site.uottawa.ca/~bkantarc/publications.html' className={css(styles.link)} target="_blank">
                            <MenuItem>Publications</MenuItem>
                        </Link>
                        <Link to='/surveys-tutorials' className={css(styles.link)}>
                            <MenuItem>Surveys & Tutorials</MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
                <Link to='https://ieee-dataport.org/search/node/Burak%20Kantarci' className={css(styles.link)} target="_blank">
                    <Button 
                        variant='link'
                        className={css(styles.button, activeLink === "/datasets" && styles.activeButton)}
                    >
                        Datasets
                    </Button>
                </Link>
                <Menu>
                    <MenuButton as={Button} variant='link' className={css(styles.buttonMenu, (activeLink === "/news" || activeLink === "/media") && styles.activeButton)}>
                        News
                    </MenuButton>
                    <MenuList>
                        <Link to='/news' className={css(styles.link)}>
                            <MenuItem>Announcements</MenuItem>
                        </Link>
                        <Link to='/media' className={css(styles.link)}>
                            <MenuItem>Media</MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
                <Link to='/sponsors' className={css(styles.link)}>
                    <Button 
                        variant='link'
                        className={css(styles.button, activeLink === "/sponsors" && styles.activeButton)}
                    >
                        Sponsors
                    </Button>
                </Link>
                <Link to='/collaborators' className={css(styles.link)}>
                    <Button 
                        variant='link'
                        className={css(styles.button, activeLink === "/collaborators" && styles.activeButton)}
                    >
                        Collaborators
                    </Button>
                </Link>
                {/*
                <Link to='/contact-us' className={css(styles.link)}>
                    <Button 
                        variant='link'
                        className={css(styles.button, activeLink === "/contact-us" && styles.activeButton)}
                    >
                        Contact Us
                    </Button>
                </Link>
                */}
                
                
            </SimpleGrid>
            {/*
            <Button
            variant='solid'
            size="x-sm"
            className={css(styles.eng)}
            >
            need to import "Text" from from "@chakra-ui/react"
            <Link to='/fr'>
                <Text fontSize={14} padding={1} color='white' fontWeight='bold' mr={2} ml={2}>
                EN/FR
                </Text>
            </Link>
            </Button>*/}
            
        </Flex>
        <Flex className={css(styles.slidingTab)}>
            <Flex fontWeight='bold' fontSize={16} justifyContent="center" position="relative" overflowX="auto">
            {/* className={css(styles.content)} */}
            <Text fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" as="i">
                <span style={{ color: 'darkgreen' }}>
                    Smart Connected Vehicles Innovation Centre (SCVIC)
                </span>
                <span style={{ color: 'forestgreen' }}>
                    &nbsp;&nbsp;&amp;
                </span>
                <span style={{ color: 'forestgreen' }}>
                    &nbsp;Next
                </span>
                <span style={{ color: 'darkgreen' }}>
                    &nbsp;Generation&nbsp;
                </span>
                <span style={{ color: 'forestgreen' }}>
                    Co
                </span>
                <span style={{ color: 'darkgreen' }}>
                    mmunications and Computing&nbsp;
                </span>
                <span style={{ color: 'forestgreen' }}>
                    N
                </span>
                <span style={{ color: 'darkgreen' }}>
                    etworks (
                </span>
                <span style={{ color: 'forestgreen' }}>
                    NEXTCON
                </span>
                <span style={{ color: 'darkgreen' }}>
                    ) Lab
                </span>

                </Text>
            {/* &emsp; &emsp; &emsp; &emsp;
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL)
            &emsp; &emsp; &emsp; &emsp;
            TRAINING AND RESEARCH IN AUTONOMOUS VEHICLES FOR RELIABLE SERVICES IN THE AIR AND ON LAND (TRAVERSAL) */}
            </Flex>
        </Flex>

    </Box>
    <SimpleGrid columns={2} spacing={0} textAlign="center" marginTop={100}>
            <Flex justifyContent="center">
                <Image src="NEXTCON-image001.jpg" alt="NEXTCON-image001" width="60%" />
            </Flex>
            <Flex justifyContent="center">
                <Image src="NEXTCON-KANATA.jpg" alt="NEXTCON-KANATA" width="80%" />
            </Flex>
    </SimpleGrid>
    <Flex justifyContent="center" position="relative" overflowX="auto">
      <Text fontSize="20.5" fontFamily="'Times New Roman'" fontWeight="normal" align="center">
        <b>Director: Dr. Burak Kantarci,</b> Professor and University Research Chair in AI-Enabled Secure Networking for Smart Critical Infrastructures, School of Electrical Engineering and Computer Science, University of Ottawa
      </Text>
    </Flex>
    <hr />
    </>
    );
}

const styles = StyleSheet.create({
    parent: {
      width: '100vw',
      backgroundColor: ' #d3d3d3  ' ,
      position: 'fixed',
      top: 0,
      zIndex: 10,
    //   boxShadow: '2px 3px #D3D3D3',
    },
    nav: {
        width: '80%',
        padding: '0.5%',
        justifyContent: 'space-around',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    link: {
        textDecoration: 'none',
        marginRight: '3%',
        marginLeft: '3%',
        width: '100%',
    },
    button: {
        color: '#315369',
        marginRight: '4%',
        marginLeft: '5%',
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '1.5', // Ensure line height is consistent
        display: 'flex', // Use flex to center text and icon vertically
        alignItems: 'center', // Align items vertically
        justifyContent: 'center', // Center content horizontally
        position: 'relative', // Needed to position the pseudo-element correctly
        width: '100%',
        height: '48px', // Ensure consistent height
        ":hover": {
            color: '#DC242C'
        }
    },
    buttonMenu: {
        color: '#315369',
        marginRight: '4%',
        marginLeft: '5%',
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: '1.5', // Ensure line height is consistent
        display: 'flex', // Use flex to center text and icon vertically
        alignItems: 'center', // Align items vertically
        justifyContent: 'center', // Center content horizontally
        height: '48px', // Ensure consistent height
        position: 'relative', // Needed to position the pseudo-element correctly
        width: '100%',
        ":hover": {
            color: '#DC242C',
        },
        ":after": { // Adding the arrow as a pseudo-element
            content: '""', // This is necessary to create a pseudo-element
            border: '5px solid transparent', // Creating the arrow shape
            borderTopColor: '#315369', // Arrow color matches the button text color
            position: 'absolute',
            top: '100%',
            right: '20px', // Position the arrow to the right of the text
            transform: 'translateY(-50%)', // Center the arrow vertically
            transition: 'border-top-color 0.3s', // Smooth transition for hover
        },
        ":hover:after": {
            borderTopColor: '#DC242C' // Change arrow color on hover
        }
    },
    activeButton: {
        // color: ' #080a5a ',
        color: '#DC242C', // Define the style for the active link
    },
    eng: {
        marginRight: 5,
        // backgroundColor: '#DC242C',
        backgroundColor: '#315369',
        paddingLeft: 5,
        paddingRight: 5,
        ":hover": {
            backgroundColor: '#E43C44'
        },
        marginTop: 5,
        marginBottom: 5
        // height: '100%'
    },
    slidingTab: {
        width: '100%',
        position: 'relative',
        justifyContent: 'center',
        whiteSpace: 'normal', // Ensure text wraps instead of overflowing
        overflowX:"auto"
    },
      
    content: {
        animationName: {
                '0%': {
                    transform: 'translateX(100%)',
                },
                '100%': {
                    transform: 'translateX(-100%)',
                },
            },
            animationDuration: '15s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite',
            color: 'black', // Set text color to white or your preferred color
            fontWeight: 'bold',
            whiteSpace: 'normal',
            left: 0,
            width: '100%', // Ensure the content takes up the full width
    },
});

export default Navbar;

import React from 'react';

const Header = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2
        style={{
          fontSize: `${24}px`,
          fontWeight: 'bold',
          color: 'black',
          fontFamily: 'Times New Roman, Times, serif',
        }}
      >
        Burak Kantarci, PhD., P.Eng., SMIEEE, SM-ACM

      </h2>

      <h2
        style={{
          fontSize: `${20}px`,
          fontStyle: 'italic',
          fontWeight: 'bold',
          color: 'dimgray  ',
          fontFamily: 'Times New Roman, Times, serif'
        }}
      >
        Professor & University Research Chair in AI-Enabled Secure Networking for Smart Critical Infrastructures
        <br />
        IEEE Communications Society Distinguished Lecturer
        <br />
        IEEE Systems Council Distinguished Lecturer
      </h2>



    </div>
  );
};

export default Header;
